html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  line-height: 1.5;
  font-size: 17px;
  color: #444;
  font-weight: 400;
}

body * {
  box-sizing: border-box;
}
.App {
  padding: 0 30px;
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
  background: #fff;
}

h1, h2, h3, h4, h5, h6 {
  color: #08909f;
  margin-top: 0;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 600;
}

h1 {
  min-height: 100px;
  transition: 300ms ease-in-out;
  background: #05909f;
  padding: 15px 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-top: 5rem;
  width: calc(100% + 60px);
  margin-left: -30px;
  z-index: 1;
  text-align: center;
  font-size: 22px;
  position: sticky;
  top: 0;
  font-size: 18px;
  text-align: left;
}

.content:first-child h1 {
  margin-top: 0;
}

div:not(.card) > h2 {
  width: 100%;
  background: linear-gradient(45deg, #08909f1f, transparent);
  padding: 20px 30px;
}

.cards-wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3rem;
}
@media screen and (max-width: 960px) {
  .cards-wrapper {
    grid-template-columns: 1fr;
  }
  div:not(.card) > h2 {
    font-size: 18px;
  }
}

ol, ul {
  margin-bottom: 30px;
  margin-top: 0;
}

p {
  margin-bottom: 18px;
  margin-top: 0;
}

li {
  margin-bottom: 8px;
}

.card {
  border: 1px solid #0a909f;
}

.references {
  cursor: pointer;
  background: #fff;
  border: 1px solid #08909f;
  padding: 30px;
  margin-bottom: 2rem;
  position: relative;
  border-right: 0;
  border-left: 0;
}

.references * {
  pointer-events: none;
}

.references:before {
  content: "+";
  position: absolute;
  right: 20px;
  top: 5px;
  color: #08909f;
  font-size: 40px;
  font-weight: 300;
  transform: rotate(45deg);
  transform-origin: center center;
  transition: 300ms ease-in-out;
  text-transform: uppercase;
  display: block;
  height: 70px;
}
.references.hidden:before {
  transform: rotate(0deg);
}
.references h4 {
  margin-bottom: 0;
}

.references ul {
  margin-top: 30px;
  padding-left: 17px;
}

.card h4 {
  background: #08909f;
  color: #fff;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 0;
}

.card h5 {
  text-align: center;
  border-top: 1px solid #0a909f;
  border-bottom: 1px solid #0a909f;
  padding: 20px 0;
}

.card p {
  padding: 0 20px;
}

.references.hidden div {
  display: none;
}

.table-of-contents ul {
  list-style: none;
  padding-left: 20px;
  padding-right: 20px;
}

.toc-level-h1 {
  font-size: 18px;
  margin-top: 30px;
  font-weight: 700;
}

.toc-level-h2 {
  margin-left: 30px;
  font-size: 18px;
}

.toc-level-h3 {
  margin-left: 50px;
  font-size: 16px;
}

.table-of-contents a {
  text-decoration: none;
  color: #08909f;
  display: block;
  text-transform: lowercase;
}

.table-of-contents a:first-letter {text-transform: uppercase}

li.toc-level-h2 a, li.toc-level-h3 a {
  color: #000;
}

nav {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #08909f;
  padding: 20px 60px;
  z-index: 9;
}

nav > div > div {
  cursor: pointer;
}

nav > div {
  display: flex;
  z-index: 1;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.table-of-contents {
  position: fixed;
  height: calc(100% - 70px);
  background: white;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  padding-bottom: 50px;
} 

.table-of-contents > div {
  width: 100%;
  display: flex;
  overflow-y: auto;
  height: calc(100% - 50px);
  flex-direction: column;
  align-items: center;
}

#home-nav {
  width: 40px;
  margin-right: auto;
  margin-left: unset;
  cursor: pointer;
}

#toc-nav {
  width: 40px;
  height: 37px;
  cursor: pointer;
  margin-left: auto;
  margin-right: unset;
  position: relative;
}

#toc-nav span:before,
#toc-nav span:after {
  content: "";
  height: 2px;
  width: 30px;
  background: #fff;
  display: block;
  position: absolute;
  right: 0;
}
#toc-nav span:before {
  top: -10px;
}
#toc-nav span::after {
  bottom: -10px;
}
#toc-nav span {
  position: absolute;
  right: 0;
  height: 2px;
  width: 18px;
  background: #fff;
  display: block;
}

.table-of-contents.hidden,
.search-container.hidden {
  display: none;
}

.table-of-contents h2 {
  min-height: 100px;
  width: 100% !important;
  background: #08909f !important;
  color: #fff;
  margin-bottom: 0;
  text-align: left;
  display: flex;
  align-items: flex-end;
}

.search-results ul {
  list-style: none;
  padding-left: 0;
}

.search-results ul a {
   color: #333; 
   text-decoration: none;
}

.search-results ul li {
  font-size: 14px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.search-results ul li h3 {
  margin-bottom: 2px;
}

.search-container {
  position: fixed;
  height: calc(100% - 70px);
  background: white;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
} 

.search-input-wrapper input::placeholder {
  color: #fff;
}

.search-input-wrapper {
  width: 100%;
  position: relative;
}

.search-input-wrapper > div {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input-wrapper input {
  width: 100%;
  height: 50px;
  background: #04818F;
  color: #fff;
  padding-left: 50px;
  padding-right: 50px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  caret-color: #fff;
}

.search-input-wrapper input:focus,
.search-input-wrapper input:focus-visible {
  outline: 1px solid #ffffff59;
}

.search-container-inner {
  height: 100%;
}

.search-results {
  padding: 30px 30px 50px;
  overflow-y: auto;
  height: calc(100% - 136px);
}

.search-header {
  background: #04909F;
  padding: 20px 30px 20px;
  min-height: 100px;
  display: flex;
  align-items: flex-end;
}

.search-input-wrapper > button, .table-of-contents button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  background: none;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#search-nav {
  cursor: pointer;
}

mark {
  background: #0a909f42;
}

.table-of-contents:after, .search-container:after {
  content: "";
  display: block;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, white, transparent);
}

.table-of-contents button {
  height: 60px;
  width: 70px;
  top: 0;
}

.nav-item svg {
  transition: 300ms ease-in-out;
}

.nav-item span, .nav-item span:after, .nav-item span:before {
  transition: 300ms ease-in-out;
}

.nav-item.active span, .nav-item.active span:before, .nav-item.active span:after,
.nav-item:hover span, .nav-item:hover span:before, .nav-item:hover span:after {
  background: #000 !important;
  transition: 300ms ease-in-out;
}

.nav-item.active svg, .nav-item:hover svg {
  filter: invert(1);
  transition: 300ms ease-in-out;
}

.install-wrapper {
  position: fixed;
  right: 0;
  top: 50vh;
}

.install-wrapper button {
  appearance: none;
  -webkit-appearance: none;
  background: #0a909f;
  border: none;
  padding: 10px 15px;
  border-radius: 4px 0 0 4px;
  color: #fff;
  box-shadow: 3px 3px 10px #0000002e;
  font-weight: 700;
  cursor: pointer;
  transition: 300ms ease-in-out;
}

.install-wrapper button:hover {
  background: #000;
}

.login-wrapper {
  background: #05909f;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.login-wrapper h1, .navigation-splash h1 {
  font-weight: 400;
  font-size: 34px;
  margin: 0 0 10px 0;
  padding: 0;
  text-transform: none;
  width: 100%;
  text-align: left;
  min-height: unset;
}

.login-wrapper h1 + svg, .navigation-splash h1 + svg {
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: auto;
}

.login-wrapper p, .navigation-splash p {
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px;
  line-height: 1.2;
  margin-right: auto;
}

input[type="password"] {
  height: 47px;
  border: 0;
  width: 100%;
  margin-bottom: 30px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 6px;
}

.login-wrapper button {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  height: 48px;
  border-radius: 100px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background: #006C78;
  cursor: pointer;
  transition: 300ms ease-in-out;
}

.login-wrapper button:hover {
  background: #000;
}

.login-inner {
  position: relative;
  z-index: 1;
  padding: 30px;
}

.login-wrapper #logingraphic {
  position: absolute;
  bottom: -120px;
  right: -70px;
  pointer-events: none;
  height: 270px;
}

.references li p {
  margin-bottom: 0;
}

.page-indicator {
  position: fixed;
  top: 13px;
  right: 10px;
  background: #08909f;
  font-size: 12px;
  border: 1px solid #fff;
  z-index: 5;
  border-radius: 14px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}


ul p {
  margin-bottom: 0;
}

content > ul, content > ol {
  padding-left: 20px;
}

.content h3 {
  font-size: 16px;
  border: 2px solid #0a909f4d;
  padding: 15px;
}

.content h2 {
  background: linear-gradient(45deg, #06909f73, #06909f14, transparent);
  padding: 15px 30px;
  font-size: 20px;
  margin-right: -30px;
  margin-left: -30px;
}
.table-wrapper {
  padding-bottom: 30px;
  overflow-x: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid #06909f;
}
th, td {
  padding: 8px;
  text-align: center;
}

.blue-cell {
  background: #06909f;
  color: #fff;
}

table tr:nth-child(even) td {
  background: #06909f17;
}

.content img {
  max-width: 100%;
}

button.login-app-btn {
  margin-top: 10px;
}

.content a {
  color: #08909f;
}

@media all and (display-mode: standalone) {
  button.login-app-btn {
    display: none;
  }
}


nav.navigation-splash {
  height: 100%;
  z-index: 9999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

nav.navigation-splash > div {
  justify-content: center;
  margin: 10px 0 !important;
  border: 1px solid #eee;
  width: 100% !important;
  border-radius: 4px;
  height: 50px !important;
  font-weight: 500;
}

nav.navigation-splash #logingraphic {
  bottom: -80px;
  height: 270px;
  pointer-events: none;
  position: absolute;
  right: -70px;
}

.login-app-btn .install-wrapper {
  position: static;
  margin-top: 10px;
}

.login-app-btn .install-wrapper button {
  font-weight: 400;
}

.install-wrapper p {
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  margin-top: 15px;
}

.install-wrapper {
  background: #03708196;
  padding: 18px 10px 20px 10px;
  display: block;
  border-radius: 4px;
}

.install-wrapper p {
  margin: 0;
}

.App > div > .show ~ .page-indicator {
  display: none;
}

nav.navigation-splash ~ div,
nav.navigation-splash ~ nav {
  display: none;
}